<template>
  <div>
    <h1><b>Refund Policy</b></h1>
    <h4>1. Products</h4>
    <p>
      MurshiSoft always makes every effort to eliminate any reported defects in
      the shortest time possible. In almost all cases we are able to quickly and
      efficiently solve all the problems associated with our products and/or
      your environment.
    </p>
    <h5 class="policy">7-Day Free Access</h5>
    <p>
      To each of our products with the annual license, we offer 7-Day Free
      Access which allows you to carefully check all its features before
      reaching a purchase decision. We encourage you to take the opportunity to
      test our products freely as it will prevent many unnecessary
      misunderstandings. If you are unsure whether our products satisfy your
      expectations, we urge you to create a ticket and ask for clarification.
    </p>
    <h5 class="policy">30-Day Guarantee</h5>
    <p>
      Bearing in mind your highest comfort and satisfaction of our customers, we
      allow a single, unconditional and completely free license switch of each
      product with the annual license during the first 30 days from the moment
      of its purchase. The obtained product license can be switched on your
      request to the license of any other product from our offer retaining
      differences in price, remaining the license validity period and excluding
      refunds. Each subsequent license switch on demand requires an additional
      payment amounting to a 25% of the target product's cost plus the possible
      difference in price between both.
    </p>
    <h5 class="policy">Refunds</h5>
    <p>
      Since MurshiSoft offers non-tangible and non-returnable goods, we do not
      issue refunds once the order is finalized and the product is activated.
      The only exception is when customer requests the refund within 14 days
      from the moment of purchase and the product itself has been neither
      downloaded nor used by the customer. As a customer, you are responsible
      for understanding this upon purchasing any item on our website.
    </p>
    <p>
      The purchase of a product implies acceptance of all its advantages and
      disadvantages. Refunds on the grounds of customer's believes that the
      product does not meet their expectations will not be issued.
    </p>
    <p>
      Once you have successfully issued the refund, you will see the funds back
      on your card in 3-5 business days or in their bank account within 5-10
      business days.
    </p>
    <p></p>
    <p>
      Open source products shall not be reimbursable or exchanged. Please
      consider your purchase carefully.
    </p>
    <h4>2. Custom Software Development</h4>
    <p>
      If you conclude that our custom software development services do not meet
      your expectations, you are able to get a full or partial refund depending
      on the development progress status before the project is finished:
    </p>
    <ul class="list-styled">
      <li>100% refund when the development has not started yet</li>
      <li>
        50% refund when the development has been started, but is finished in 50%
        or less of total development
      </li>
      <li>
        25% refund when the development has been started, but is finished in 75%
        or less of total development
      </li>
    </ul>
    <h4>3. Product Services</h4>
    <p>
      Our offered services related to products cover Installation Service,
      Template Integration and Priority Support. Since such services are
      non-tangible and non-returnable, we do not issue refunds once we start the
      work. Nevertheless, we assure you of the highest service quality and
      guarantee to try our hardest to meet your expectations.
    </p>
    <p>
      Any questions concerning this Refund Policy may be directed to
      contact@murshisoft.com.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
